.buyer {
  /* Login */
  .hide{
    display: none;
  }
  div.dropdown-menu{
    button.dropdown-item{
        font-size: small;
    }
  }
  .card-body{
    padding: 1.20rem !important;
  }
  .sort-icon-asc{
    font-size: medium;
    margin-top: 6px;
    margin-left: 6px;
    position: absolute;
  }
  .sort-disable{
    color: darkgray !important;
  }
  .sort-icon-desc{
    font-size: medium;
    margin-top: 6px;
    margin-left: 6px;
    position: absolute;
  }
  
  .grid-header{
    padding: 10px;
    color: white;
    span{
      text-transform:uppercase;
      font-size: small;
    }
    background-image: linear-gradient(to bottom, #0572B0 0%, #035c8e 100%);
  }
  .text-white{
    color: white !important;
  }
  .grid-rows {
    font-size: small;
    & > .row{
      border-top: 1px solid #dee2e6 !important;  
    }
    & > .row:first-child {
      border-top: none !important;
    }
    .actionBtn{
      & > button{
        height: 30px;
        width: 90px;
        padding: 0px;
        padding-right: 15px;
        svg{
          position: absolute;
          margin-top: 2px;
          margin-left: 5px;
        }
      }
    }
  }
  .loadProgress{
    text-align: center;
    margin-top: 6em;
  }
  .namelbl{
    font-weight: bold;
    white-space: nowrap;
  }
  
  .card-height{
    height: 400px;
  }
  .row-space-4em{
    margin-top: 7em;
    margin-bottom: 1.4em;
  }
  /* Buttons */
  .btn-primary {
    background-color: #0572B0 !important;
    border-color: #0572B0 !important;
  }

  .btn-outline-primary {
    color: #0572B0 !important;
    border-color: #0572B0 !important;
  }

  .btn-outline-primary:hover {
    background-color: #0572B0 !important;
    color: white !important;
    border-color: #0572B0 !important;
  }

  .btn-link {
    color: #0572B0 !important;
  }

   .card {
    border-radius: 0px !important;
    border: 1px solid rgba(0, 0, 0, .125) !important;
} 

.aging-report-modal .box {
  width: 604px;
  height: 486px;
  background: #FFFFFF;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.dropZone{
  div{
    height: 100px !important;
    padding: 20px;
    // background: #FFFFFF !important;
    // border: 1px dashed #999999 !important;
    border:none !important;
    background-image: url("./assets/images/Rectangle.png");
    box-sizing: border-box;
    border-radius: 5px !important;
    background-repeat: no-repeat;
  }
}

.po-upload-popup .box {
  height: 85% !important;
  max-height: 85% !important;
  width: 70%;
  margin-top: 2.5%;
  overflow: hidden;
}

.rt-tbody{
  max-height: 30vh;
}
.rt-table{
  max-height: 30vh;
}


.po-upload-csv-popup .box {
  height: 90% !important;
  max-height: 90% !important;
  width: 75%;
  margin-top: 2.5%;
  overflow: hidden;
}
.poupload>div{
  height: 25vh !important;
  border-radius: 0px !important;
  width:90vw!important;
  overflow-y: auto;
  text-align: center;
}

.buyer-submit-popup .box {
  width: 530px;
  height: 400px;
  background: #FFFFFF;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
  border-radius: 20px;

}

.buyer-submit-po{
  z-index:999 !important;
}

.buyer-submit-details-popup .box {
  width: 700px;
  height: 600px;
  background: #FFFFFF;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
  max-height: 85% !important;
}

.po-upload  .form-label-style:after{
  content:"*" ;
  color:red   ;
  font-weight: 400;
  font-size: 15px;

}
.buyer-submit-details-popup .form-group{
  margin-bottom: 0.75rem !important;
}

.po-upload-popup .react-date-picker__wrapper{
  border:none !important;
}



.po-upload-popup .react-calendar{
  width:325px !important;
  position: fixed;
  border: 1px solid #999;
  background: #F9F9F9;
  box-shadow:  0px 0px 60px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.anchorstyle{
  cursor: pointer;
  text-decoration: none;
  font-weight: bold;
  color:#5F9FBC;
}

.react-date-picker--disabled{
	background-color: #e9ecef !important;
}

.react-date-picker__clear-button{
  display: none !important;
}

.alert-zindex{
  z-index:99999;
}

// .modal-backdrop{
//   z-index:999;
// }

}
