.funder {
  /* Login */
  .hide{
    display: none;
  }
  div.dropdown-menu{
    padding: 10px;
    button.dropdown-item{
        font-size: small;
    }
  }
  .card-body{
    padding: 1.20rem !important;
  }
  .dropZone{
    div{
      height: 100px !important;
    }
  }
  .sort-icon-asc{
    font-size: medium;
    margin-top: 6px;
    margin-left: 6px;
    position: absolute;
  }
  .sort-disable{
    color: darkgray !important;
  }
  .sort-icon-desc{
    font-size: medium;
    margin-top: 6px;
    margin-left: 6px;
    position: absolute;
  }
  
  .grid-header{
    padding: 10px;
    color: white;
    span{
      text-transform:uppercase;
      font-size: small;
    }
    background-image: linear-gradient(to bottom, #5ea03b 0%, #638e00 100%);
  }
  .text-white{
    color: white !important;
  }
  .grid-rows {
    font-size: small;
    & > .row{
      border-top: 1px solid #dee2e6 !important;  
    }
    & > .row:first-child {
      border-top: none !important;
    }
    .actionBtn{
      & > button{
        height: 30px;
        width: 90px;
        padding: 0px;
        padding-right: 15px;
        svg{
          position: absolute;
          margin-top: 2px;
          margin-left: 5px;
        }
      }
    }
  }
  .loadProgress{
    text-align: center;
    margin-top: 6em;
  }
  .namelbl{
    font-weight: bold;
    white-space: nowrap;
  }
  
  .card-height{
    height: 400px;
  }
  .row-space-4em{
    margin-top: 7em;
    margin-bottom: 1.4em;
  }
  /* Buttons */
  .btn-primary {
    background-color: #5ea03b !important;
    border-color: #5ea03b !important;
  }

  .btn-outline-primary {
    color: #5ea03b !important;
    border-color: #5ea03b !important;
  }

  .btn-outline-primary:hover {
    background-color: #5ea03b !important;
    color: white !important;
    border-color: #5ea03b !important;
  }

  .btn-link {
    color: #5ea03b !important;
  }


  .anchorstyle{
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
  }
  .document-border{
    border: 1px solid #6c757d!important;
    padding: 10px;
    border-radius: 0px;
    margin-left: 1.5em;
  }
  .view-modal-left-scroll,.view-modal-right-scroll{
    max-height: 80vh;
    overflow: auto;
    overflow-x: hidden;
  }

  .make-offer-popup .box {
    height: auto;
    max-height: 575px;
    width: 40%;
  }

  .make-offer-body{
    overflow-y: auto !important;
    overflow-x: hidden !important;
    height: 70vh !important;
  }

  div>.dashboard-card{
    border-radius: 20px !important;
    border:none !important;
    height:365px !important;
  }

  .card {
    border-radius: 0px !important;
    border: 1px solid rgba(0, 0, 0, .125) !important;
} 
}


.btn-submit-agreement {
  margin-top: 90px !important;
}

.modal-header .agreement-close{
  margin:-0.25rem -1rem -1rem auto !important;
}

.dashboard-card-body {
  height: 300px;
  overflow: auto;
  position: relative;
}

.fundingCriteria-industries{
  height: calc(100vh - 180px);
  overflow-y: auto;
}

// .funder-settings-list{
//   height: calc(100% - 90px) !important;
//   background-color: #F9F9F9;
// }

.funder-dashboard{
  height: calc(100% - 66px) !important;
}

.rct-node-icon{
  display: none;
}

.geography-card{
  height: 45vh !important;
  max-height: 43;
  overflow-y: auto;
}