.header-navbar, .side-navbar {
    background-color: #063A52;
}

.header-titlebar {
    background-color: #F9F9F9;

    h4{
        color: #063A52;
    }
}

.login-wrapper {
    background: linear-gradient(97.42deg, rgba(13, 27, 35, 0.75) 70.32%, rgba(13, 24, 33, 0) 90.36%);
}

.awaiting-text-color{
    color: #4F4F4F
}
.archived-text-color{
    color: #930000
}
.progress-text-color{
    color: #4F984F
}

h4,h5{
    color: #063A52;
}

// .buyer {
//     .content-container.fundingrequest-list {
//         display: none;
//     }
// }

.link-text-color
,option{
    color:#5F9FBC;
}

.round-badge{
    background-color:#0572B0;
}

.message.sent .message-inner {
    background: #0572B0;
    color: #ffffff;

    a {
        color: #ffffff !important;
    }
}

.message.received .message-inner {
    background: #5ea03b;
    color: #ffffff;

    a {
        color: #ffffff !important;
    }
}