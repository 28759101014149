body {
  margin: 0;
  padding: 0;
  font-family: "Cairo", sans-serif !important;
  background-color: #F5F5F5 !important;
}

/* Buttons */
.btn {
  /* border-radius: 0px !important; */
  font-size: 12px!important;
  font-weight: 700!important;
  line-height: 1.5 !important;
}

/* Cards  */
/* .card {
  border-radius: 0px !important;
  border: 1px solid rgba(0,0,0,.125) !important;
} */

/* Forms  */
/* .form-control {
  border-radius: 0px !important;
}

.form-group {
  margin: 0 !important;
} */

.dark-row {
  background-color: whitesmoke;
}

.tall-select {
  height: 300px !important;
}


/* Tabs */
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #2A2C44 !important;
  border-bottom: 3px solid #2A2C44 !important;
  background: none !important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active .tab-line {
  color: #2A2C44 !important;
  padding-bottom: 9px !important;
  border-bottom: 2px solid #2A2C44 !important;
}

.nav-tabs .nav-link {
  border: 0px solid transparent !important;
  border-top-left-radius: .25rem !important;
  border-top-right-radius: .25rem !important;
}

.nav-tabs a {
  color:#333 !important;
}

a label {
  cursor: pointer;
}
.blue-text {
  color: #007bff;
  font-weight: bold;
}

.modal-text-name{
  font-family: 'Cairo';
  font-weight: bold;
  font-size: 12px;
  text-align: center;
  text-transform: uppercase;
  color: #255F79;
}

.modal-document-num{
  font-weight: normal;
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  color: #575757;
}

.form-label-style ,.label-style{
  font-weight: bold;
  font-size: 13px;      
  line-height: 1.5;   
  text-transform: uppercase;
  color: #255F79;
  margin-bottom: 5px;  
  display: flex;        
  align-items: center; 
  
}

.submit-invoice-msg{
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: justify;
  color: #929292;
}

.due-date-label{
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #575757;
}

.modal-submit-button{
  width: 121px;
  background: #255F79;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
  margin-left: 5px;
}

.modal-close-button{
  width: 121px;
  background: #5F9FBC;
  border-radius: 5px;
  text-transform: uppercase;
  text-align: center;
}

.row-select-count{
  font-weight: 600;
  font-size: 14px;
  line-height: 26px;
  color: #5F9FBC;
  display: inline-block;
}

.modal-content-background{
  background: #F9F9F9 !important;
  box-shadow: 0px 0px 60px rgb(0 0 0 / 10%);
  border-radius: 20px !important;
}

.modal-content-background > div:last-child {
  overflow-y: auto;
  overflow-x:hidden;
  max-height: 100%;
}

.create-funding-invoice-msg{
  font-family: 'Cairo';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  text-align: center;
  color: #575757;
}

.selected-customers{
  font-family: Cairo;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 34px;
  text-align: center;
  text-transform: uppercase;
  color: #575757;
}

.cursor-pointer {
  cursor: pointer;
}

select{
  padding-top:0px !important;
}

.alert-warning{
  color:#FFFFFF !important;
  background:#FFB45B !important;
  border-radius: 20px !important;
}

.view-transaction-title{
  font-weight: 600;
  font-size: 25px;
  text-align: left;
  text-transform: uppercase;
  color: #575757;
}

.view-transaction-details{
  background:#FFFFFF;
}

.view-details-header{
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 34px;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  color: #575757;
  font-weight: 900;
}

.view-transaction-amt{
  font-family: Cairo;
  font-style: normal;
  font-weight: 600;
  font-size: 19px;
  line-height: 36px;
  color: #5F9FBC;
}

.dashboard-card{
  border-radius: 20px !important;
  border:none !important;
  height:365px !important;
}

.dashboard-card-header{
  font-weight: 600;
  font-size: 18px;
  color: #063A52;
}

.tableTotal>td{
  border: none !important;
}

#profileContainer .form-label-style:after{
  content: " *";       
  color: red;         
  font-weight: normal; 
  font-size: 13px;     
  margin-left: 1px;    
  display: inline;  
}

#profile-website-url:after{
  content:none !important;
}
.hourglass {
  width: 1em;
  /* height: 2em; */
  -webkit-animation: hourglass 1s linear infinite;
          animation: hourglass 1s linear infinite;
}

.outer {
  fill: #ffffff;
}

.middle {
  fill: #063A52;
}

@-webkit-keyframes hourglass {
  0% {
    transform: rotate(0deg);
    box-shadow: inset #ffffff 0 0em 0 0, inset #063A52 0 -2em 0 0, inset #ffffff 0 -4em 0 0;
  }
  80% {
    transform: rotate(0deg);
    box-shadow: inset #ffffff 0 -2em 0 0, inset #063A52 0 -2em 0 0, inset #ffffff 0 -2em 0 0;
  }
  100% {
    transform: rotate(180deg);
    box-shadow: inset #ffffff 0 -2em 0 0, inset #063A52 0 -2em 0 0, inset #ffffff 0 -2em 0 0;
  }
}

@keyframes hourglass {
  0% {
    transform: rotate(0deg);
    box-shadow: inset #ffffff 0 0em 0 0, inset #063A52 0 -2em 0 0, inset #ffffff 0 -4em 0 0;
  }
  80% {
    transform: rotate(0deg);
    box-shadow: inset #ffffff 0 -2em 0 0, inset #063A52 0 -2em 0 0, inset #00b7c6 0 -2em 0 0;
  }
  100% {
    transform: rotate(180deg);
    box-shadow: inset #ffffff 0 -2em 0 0, inset #063A52 0 -2em 0 0, inset #00b7c6 0 -2em 0 0;
  }
}

.formErrors{
  color: red;
  font-size: small;
}

.Signer-font{
  font-family: 'Whisper';
  font-size: 2rem;
  font-weight: 600;
}

.signatureTooltip {
  position: relative;
  display: inline-block;
  /* border-bottom: 1px dotted black; */
}

.signatureTooltip .tooltiptext {
  visibility: hidden;
  width: 450px;
  background-color: #000;
  color: #fff;
  border-radius: 6px;
  padding: 15px;
  position: absolute;
  z-index: 99999;
  bottom: 10%;
  left: 50%;
  margin-left: -60px;
  opacity: 0;
  transition: opacity 0.3s;
}

.signatureTooltip .tooltiptext::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #555 transparent transparent transparent;
}

.signatureTooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


.signature-tooltip{
  display: flex;
  flex-direction: column;
  background-color: #000;
  color: white;
  padding: 15px;
  /* width:40%; */
}

.signature-popover-width{
  width:40% !important;
  z-index:999999;
}

.full-height {
  height: 100% !important;
}

.flex-grow {
  flex-grow: 1 !important;
}

.signature-zIndex, #mouse-over-popover, .Toastify__toast-container{
  z-index: 999999 !important;
}

.agreement-digital-dropzone{
  height: 12vh;
  border-width: 2px;
  border-color: grey;
  border-style: dashed;
}

.agreement-manual-dropzone{
  height: 20vh;
  border-width: 2px;
  border-color: grey;
  border-style: dashed;
}

.csl-table td {
  padding: 0px 0px 0px 0.5rem !important;
}

.clickable-area, .clickable-area > * {
  cursor: pointer !important;
}
.clickable-area:hover {
  outline: 1px solid #5f9fbc;
  background-color: #5f9ebc05;
}

iframe {
  display: block;       /* iframes are inline by default */
  background: #000;
  border: none;         /* Reset default border */
  width: 100vw;
}

.modal.modal-fullscreen .modal-dialog {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  max-width: none;
}

.modal.modal-fullscreen .modal-content {
  height: auto;
  height: 100vh;
  border-radius: 0;
  border: none;
}

.modal.modal-fullscreen .modal-body {
  overflow-y: auto;
}

.react-grid-Canvas{
  width:100% !important;
}

.pg-viewer-wrapper{
  overflow-y: hidden !important;
  width:100% !important;
  }
  
  .react-grid-Container{
  width: 100% ! important;
  }

  .modal-fullscreen{
    z-index:9999!important;
  }

  .form-control-border-botttom{
    border:none !important;
    border-bottom: 1px solid #ced4da !important;
    background: none !important;
  }


  * {
    margin:0;
    padding:0;
    border:0;
    -webkit-text-size-adjust:none;
    }


    .left-modal-tabs{
      width: 18%;
      background-color: #ffffff;
      padding: 15px;
      border-radius: 8px;
    }

    .right-modal-content{
      width: 82%;

      background-color: #ffffff;
      border-radius: 8px;
      /* overflow-x: none !important; */
    }

    .modal-z-index{
      z-index: 999999!important;
    }

    .react-date-picker__wrapper{
      border:none !important;
    }

    .MuiStepLabel-label{
      cursor: pointer;
    }

    .react-date-picker--disabled {
      background-color: #f0f0f0 !important;
      color: #6d6d6d;
  }

  .wordWrap{
    word-wrap: break-word;
  }

  .btn-white-space{
    white-space: inherit !important;
  }

  .title-padding > div > div > div > a {
    padding-right: 0.75rem !important;
  }

  .wordBreak{
    word-break:break-all;
  }

  #signed-term-sheet{
    z-index: 99999!important;
  }

  /* #hcaptcha-size > iframe{
    width:100% !important;
  }

  #anchor{
    width: 100% !important;
  } */

  .form-label-style.required::after {
    content: " *";       
    color: red;         
    font-weight: normal; 
    font-size: 13px;     
    margin-left: 1px;    
    display: inline;        
}

.view-funding-modal{
  display: flex;
  height:100%;
  justify-content:flex-end;
}

.translate_icon{
  cursor: pointer;
  color: rgb(255, 255, 255);
  top: 0 !important;
  position: fixed;
  right: 2px !important;
}

::-webkit-validation-bubble-message { display: none; }

.hightLight_selected_lang{
  background-color: #255F79!important;
  color:white;
}

.supplier-outline-btn{
  border: 2px solid;
  color: #255F79 !important;
  border-color: #255F79 !important;
}

.supplier-outline-btn:hover {
  border: 2px solid;
  background-color: #255F79 !important;
  color: white !important;
  border-color: #255F79 !important;
}

.buyer-outline-btn{
  border: 2px solid;
  color: #0572B0 !important;
  border-color: #0572B0 !important;
}

.buyer-outline-btn:hover {
  border: 2px solid;
  background-color: #0572B0 !important;
  color: white !important;
  border-color: #0572B0 !important;
}

.funder-outline-btn{
  border: 2px solid;
  color: #5ea03b !important;
  border-color: #5ea03b !important;
}

.funder-outline-btn:hover {
  border: 2px solid;
  background-color: #5ea03b !important;
  color: white !important;
  border-color: #5ea03b !important;
}

.view-invoice-file{
  align-items: flex-end;
}