.form-error {
  color: red;
  margin: 10px 10px 0px 10px;
  border: 1px solid #f6f6f6;
  text-align: center;
  border-radius: 5px;
  background: #f8f9fa;
  width: auto;
  padding: 5px;
}
