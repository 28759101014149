.react-table-style {
  margin: 30px;
}
.custom-drag-zone input {
  height: 140px;
  width: 600px;
  background: #95b8d1;
  margin: 40px;
  border: 1px solid #95b8d1;
  border-radius: 5px;
}
.custom-drag-zone {
  text-align: center;
}
.custom-drag-zone input[type="file"] {
  display: none !important;
}

.import-label {
  background-color: #f6f6f6 !important;
  margin-bottom: 10px;
  /* text-align: center !important; */
  font-weight: 300;
  font-size: 1.3rem;
  padding: 5px !important;
}
.example-text {
  display: inline-block;
  margin-right: 5px;
}
.sample-invoice-container {
  margin-top: 10px;
}
.import-invoice-btn {
  float: right;
  margin-top: 15px;
}

.dropzone-content {
  text-align: center;
  cursor: pointer;
}

.dropzone-content a {
  word-wrap: break-word;
}
