.loading-wrapper {
  min-height: 100vh;
}

.logo {
  width: 30%;
}

.popup-lefticon{
  margin-top: 10%;
  margin-left: 10%;
  width: 30%;
  height: 30%;
}
.popup-righticon{
  margin-top: 10%;
  margin-left: 20%;
  width: 30%;
  height: 30%;
}
.root-taxCredit{
  width: 80%;
  height: 80%;
}
.label-taxCredit{
  margin-left: 13%;
  font-weight: bold;
  font-style:inherit;
}
.label-InvoiceFactoring{
  margin-left: 35%;
  font-weight: bold;
  font-style:inherit;
}
.terms-checkbox-container{
  margin-top: 16px;
}
.terms-checkbox-container label{
  margin-bottom: 0;
}
.checkbox-termsColorChange {
   color: #c7c97e;
}  
.checkbox-termsColorChange:hover {
 
  color:rgb(255, 0, 0);
}
/* .Auth-control {
  background-color: violet !important;
} */
.inputText{
  border:2px solid #FF0000;
}

/* .spancl{
 clear: both;
} */
/* Popup style */

.h1 {
  font-weight: bold;
  color:black;
  font-family:'Times New Roman', Times, serif;
}
.whiteText {
  color: white!important;
}
#accountForm .form-control,
#inputEmail.form-control,
#inputPassword.form-control,
#forgot-password .form-control,
#accountForm .white-text-border,
.loginform .white-text-border,
.loginform .whiteText
{
 background: transparent!important;
 color: white;
}
.container-fluid {
  overflow-y: scroll;
}
.white-text-border {
  color: white!important;
  border: 1px solid #5F9FBC  !important;
  padding: 20 px;
}

.eye-icon{
  margin-left: -30px;
  padding:0px !important;
  color: white !important;
  cursor: pointer;
  background: none!important;
  border: none !important;
  z-index:9999;
}

.eye-icon-profile{
  margin-left: -35px;
  padding:0 !important;
  color: white !important;
  cursor: pointer;
  background: none!important;
  border: none !important;
  z-index:9999;
}

.num-step-container{
  display: flex;
  justify-content: space-around;
}

.num-step-item{
  display: flex;
  flex-direction: column;
  color: white;
  align-items: center;
}
.num-step-item-title{
  font-size: 40px;
  font-weight: 700;
}

.num-step-item-subtitle{
  font-size: 14px;
  font-weight: bold;
}


.form-text{
  text-transform: uppercase;
  font-weight: bold;
  font-size:12px;
}

.sub-text{
  text-transform: uppercase;
  font-weight: bold;
  font-size:10px;
}

.sign-up-btn{
  width: 50% !important;
  height:40px;
  text-transform: uppercase;
}

.react-tel-input .form-control{
  width:100% !important;
}

/* Hide Default Browser Password Visibility and Autofill Controls */
input[type="password"]::-ms-reveal, 
input[type="password"]::-ms-clear,
input[type="password"]::-webkit-credentials-auto-fill-button {
    display: none;
}

/* Target only inputs inside the .signup-form */
.signup-form input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: #ffffff !important;
  caret-color: #ffffff !important; /* Change caret color to white */
  transition: background-color 5000s ease-in-out 0s;
}

.signup-form input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
  -webkit-text-fill-color: #ffffff !important;
  caret-color: #ffffff !important; /* Change caret color to white */
}

.signup-form input {
  caret-color: #ffffff !important; /* Apply white caret color for all input fields */
}
