.data-grid-th {
    color: #255F79 !important;
    padding: 8px 2px !important;
    background: #ffffff;
    font-weight: bold !important;
    text-transform: capitalize
}

.th-list-head {
    padding: 0px 4px !important;
}

.data-flex-box {
    display: flex;
    flex-direction: column;
    flex-basis: 0;
    flex: 1;
}

.data-grid-container {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.data-grid-table-container-list {
    flex: 1;
    display: flex;
    flex-direction: column;
}

.data-grid-paper-container {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.data-grid-table-container {
    /* display: flex; */
    flex: 1;
    flex-basis: 0;
    /* max-height: 430px; */
}

.filter-view,
.edit-view,
.detailed-view {
    flex: 1;
    display: flex;
    flex-direction: column;
    min-width: 500px;
}

.filter-view.px-3 {
    padding-right: 5px !important;
}

.filter-view .MuiIconButton-edgeEnd {
    margin-right: 0px !important;
}

.filter-options-list,
.panel-view-scrollable-area {
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    flex-basis: 0;
}

.data-grid-right-panel {
    overflow: hidden !important;
    position: relative;
    width: 500px;
}

.MuiBackdrop-root {
    background: rgba(0, 0, 0, 0.5) !important;
}

.filter-panel.Mui-expanded {
    padding-bottom: 20px;
}

.list-edit-view {
    /* padding: 0px 10px; */
    overflow: auto;
    display: flex;
    flex-direction: column;
    flex: 1;
    flex-basis: 0;
}

.grid-list-addedit-sub {
    flex: 1;
    overflow: auto;
    padding: 10px;
}

.grid-addedit-actions {
    padding: 10px;
}

.data-grid-table-cell {
    /* max-width: 100px;
    display: block;
    white-space: nowrap;
    text-overflow: ellipsis; */
    overflow: hidden;
}

.clickable-rows .MuiTableRow-hover {
    cursor: pointer;
}

.wrapcell {
    /* width: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block; */
    word-break: break-all;
}

.multiPopoverButton {
    text-align: left;
}




.data-grid-header {
    padding-bottom: 24px;
    display: flex;
}

.filter-criterias {
    width: 50%;
    flex-grow: 1;
}

.filter-reset {
    width: 120px;

}

.grid-search {
    margin-top: -20px;
}

.documents-list
.MuiTableCell-stickyHeader,
.fundingrequest-list
.MuiTableCell-stickyHeader,
.contract-list
.MuiTableCell-stickyHeader,
.marketplace-list
.MuiTableCell-stickyHeader
 {
    background: white !important;
}

.fundingrequest-list
.px-5
.MuiTableCell-stickyHeader{
    background-color: #F9F9F9!important;
}


.filter-control-group:not(:first-child) {
    margin-left: 24px;
}

.filter-control select {
    border: none;
    outline: none;
}

.date-range-control-container {
    position: relative;
}

.date-range-control {
    position: absolute;
    z-index: 10;
    left: -150px;
    top: 20px;
    border: 1px solid #ccc;
}


.documents-list, .fundingrequest-list,.contract-list,.marketplace-list {
    display: flex;
    flex-direction: column;
    height: calc(100% - 100px);
}

.fundingrequest-list,.contract-list{
    height: calc(100% - 80px);
}

.MuiTableRow-root.Mui-selected, .MuiTableRow-root.Mui-selected:hover,.submit-invoice-button {
    background-color: #5F9FBC!important;
    border-radius: 5px;
}

.MuiTableRow-root.gridSelectedRow {
    background-color: #255F79 !important;
}

.MuiCheckbox-colorSecondary.Mui-checked {
    color: #255F79!important;
}

.table-list-cell{
    font-family: Cairo !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    color: #575757 !important;
}

.submit-invoice-button{
    width: 97px;
    height: 23px;
    font-family: Cairo;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 22px;
    color: #FFFCFC;
    align-items: center;
    border:none;
}

.submit-invoice-button.big {
    width: 150px;
}

.MuiTableCell-root{
    border-bottom: none!important;
}

.MuiPaper-elevation1 {
    box-shadow: none !important;
}

.Mui-selected .table-list-cell,
.Mui-selected .table-list-cell>div,
.Mui-selected .table-list-cell>.website-link,
.Mui-selected .table-list-cell .website-link,
.Mui-selected .table-list-cell>span{
    color:#FFFFFF !important;
}

.data-grid-document{
    color: #5F9FBC!important;
}

.filter-control .MuiAutocomplete-root{
    display: inline-block;
}

.MuiTableCell-alignRight {
    padding-right: 1rem!important;
}


.screen-blocker {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

/* .contract-list .filter-reset,
.contract-list .filter-criterias{
    display: none;
} */

.MuiTableCell-root,
.MuiCheckbox-root {
    padding-left: 0px!important;
}

.MuiTableSortLabel-icon{
    font-size:24px!important;
}

.MuiCheckbox-root .MuiSvgIcon-root{
    font-size: 1.2rem !important;
}

.popover-show{
    display: none !important;
}


.MuiPaper-root>.MuiButtonBase-root{
    justify-content: left !important;
}

.buildItems-row>.buildItems-cell{
    padding: 0px !important;
    background-color: #F9F9F9;
    border-bottom: 1px solid #e0e0e0 !important;
    position: relative;
}

.status-tablecell-width{
    word-wrap: break-word;
    max-width: 70%;
    width:18%;
}