.custom-input .MuiAutocomplete-inputRoot {
  background-color: #fff;
}

.custom-input .MuiInputBase-input,
.custom-input .MuiOutlinedInput-input,
.custom-input .MuiSelect-root {
  background-color: #fff;
}

/* File upload Base Styles---------------------------------- */
.customDropzone {
  color: #255F79;
  width: 100%;
  height: 20vh;
  border: 1.8px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 6px;
  box-shadow: 0 8px 8px -4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s ease, color 0.3s ease;
}

.customDropzone:hover {
  border-color: #255F79;
}

.customDropzone:hover .supportText,
.customDropzone:hover div {
  color: #255F79;
}

.disabledDropzone {
  background-color: #f2f2f2;
  border: 1.8px dashed #ccc;
  color: #999;
}

.placeholder-font {
  font-size: 15px;
}

.supportText {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  text-align: center;
  color: #929292;
  margin-top: 3px;
}

.filesContainer {
  display: flex;
  flex-direction: column;
}

.filesContainer a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 50px;
  font-size: 15px;
}

.filesContainer span {
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.scrollable-form-fields {
  max-height: calc(80vh - 50px);
  overflow-y: auto;
}

.scrollable-form-fields-01 {
  max-height: calc(73vh - 50px);
  overflow-y: auto;
}

.form-group .error-message {
  position: absolute;
  bottom: -20px;
  left: 0;
  color: red;
}




.acceptTermSheetDropZone {
  width: 90%;
  height: 200px; 
  border: 2px dashed red;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-bottom: 5px;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 6px;
}

.acceptTermSheetDropZone:hover {
  border-color: green; 
}

.acceptTermSheetDropZoneGreen {
  border-color: green; 
}


/* Common Popup Base---------------------------------- */
.common-popup-modal {
  display: block;
  z-index: 1050;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation: fadeIn 0.1s ease-out;
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
  
    to {
      opacity: 1;
      transform: scale(1);
    }
}

.common-popup-dialog {
  max-width: 100%;
  margin: 1.75rem auto;
}

.modal-xl {
  width: 100%;
  max-width: none;
}

.modal-lg {
  width: 100%;
  max-width: none;
}

.modal-xl {
  width: 100%;
  max-width: none;
}

.modal-sm {
  width: 100%;
  max-width: none;
}

.modal-full {
  width: 100%;
  max-width: none;
 
}

.modal-sm-md {
  width: 70%; 
  max-width: 700px; 
}

.common-popup-content {
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.5);
}

.common-popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #e9ecef;
}

.common-popup-title {
  font-size: 1.75rem;  
  margin: 0;  
  color: #063A52;       
}

.common-popup-close {
  background: none;
  border: none;
  font-size: 1.9rem;
  cursor: pointer;
  color: #000;
  transition: color 0.3s ease, transform 0.3s ease, text-shadow 0.3s ease;
}

.common-popup-close:hover {
  color: #000;
  transform: scale(1.1);
  text-shadow: 0 0 0.1px rgba(0, 0, 0, 1), 0 0 0.1px rgba(0, 0, 0, 1);
}

.common-popup-body {
  flex-grow: 1;
  overflow-y: auto;
  padding: 1.5rem;
  background-color: #f9f9f9;
}

.custom-scrollable-body {
  overflow-y: auto;
  margin-right: 3px;
}

@media (max-width: 768px) {

  /* Apply styles for screens less than or equal to 768px width (typical mobile devices) */
  .custom-scrollable-body {
    overflow-y: auto;
    /* Enable scrolling for smaller screens */
    max-height: 80vh;
    /* Adjust the max height as needed */
  }
}

@media (max-width: 768px) {
  .common-popup-dialog {
    width: 95%;
    margin: 1.75rem auto;
  }

  .custom-form-group {
    flex: 0 0 100%;
    max-width: 100%;
  }
}


.custom-dropzone {
  width: 100%;
  height: 110px;
  border: 1.8px dashed #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-bottom: 5px;
  background-color: #fff;
  overflow-y: auto;
  border-radius: 6px;
}


.custom-support-text {
  font-size: 0.875rem;
  color: #6c757d;
}

.custom-scrollable-dropzone {
  max-height: 150px;
  overflow-y: auto;
}

.scrollableDropzoneContent {
  margin-block: auto;
}


/* InvoicePO fields Base---------------------------------- */
.custom-form-container {
  margin: 1.5rem 0 0;
}

.custom-form-group {
  margin-bottom: 1rem;
}

.custom-form-error {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.25rem;
}



/* InvoicePO info------------------------- */
.info-container {
  padding: 10px;
}

/* common prompt--------------------------------- */
.unsaved-changes-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1050;
  /* Ensure it's above other elements */
}

.unsaved-changes-content {
  background: white;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.unsaved-changes-content p {
  margin-bottom: 20px;
  font-size: 16px;
}

.unsaved-changes-content .btn {
  margin: 0 10px;
  width: 100px;
}

/*Text under glabal spinner-------------------------*/
.spinner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner-message {
  margin-top: 10px;
  font-weight: bold;
  color: #002f53;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }
}

/* Alert Modal Base---------------------------------- */
.alert-modal {
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  width: 100% !important;
  height: 100% !important;
  background: rgba(0, 0, 0, 0.5) !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  overflow: hidden !important;
}

.alert-modal-dialog {
  width: 90% !important;
  /* Default width for smaller screens */
  max-width: 500px !important;
  /* Fixed maximum width */
  animation: alertFadeIn 0.3s ease-out !important;
}

.alert-modal-content {
  width: 100% !important;
  background: #fff !important;
  border-radius: .5rem !important;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3) !important;
  overflow: hidden !important;
  display: flex !important;
  flex-direction: column !important;
  box-sizing: border-box !important;
}

.alert-modal-header,
.alert-modal-footer {
  padding: 1rem !important;
  display: flex !important;
  justify-content: space-between !important;
  align-items: center !important;
  border-bottom: 1px solid #e9ecef;
}

.alert-modal-title {
  flex: 1;
  text-align: center;
  margin: 0;
}

.alert-modal-body {
  padding: 15px 15px 0px 15px !important;
  flex-grow: 1 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  word-wrap: break-word !important;
  /* Ensure text wraps within the modal */
}

.alert-modal-close {
  background: none !important;
  border: none !important;
  font-size: 1.7rem !important;
  cursor: pointer;
  color: #000;
  transition: color 0.3s ease, transform 0.3s ease, text-shadow 0.3s ease;
  
}

.alert-modal-close:hover {
  color: #000;
  transform: scale(1.1);
  text-shadow: 0 0 0.1px rgba(0, 0, 0, 1), 0 0 0.1px rgba(0, 0, 0, 1);
}


.alert-btn {
  min-width: 100px !important;
}

.alert-modal-dialog-centered {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/* Animation */
@keyframes alertFadeIn {
  from {
    opacity: 0;
    transform: translateY(-10%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Alert Modal Specific Styles */
.alert-zindex {
  z-index: 99999 !important;
}

/* Responsive Adjustments */
@media (min-width: 576px) {
  .alert-modal-dialog {
    width: 400px !important;
    /* Standard width for medium and larger screens */
  }
}

@media (min-width: 768px) {
  .alert-modal-dialog {
    width: 450px !important;
    /* Slightly larger for larger screens */
  }
}

@media (min-width: 992px) {
  .alert-modal-dialog {
    width: 500px !important;
    /* Maximum fixed width for even larger screens */
  }
}

.customDropzone.active {
  border-color: #4caf50;
  background-color: #e8f5e9;
}

.privacy-title {
  font-size: 18px;
  margin-bottom: 10px;
  color: #333;
}

.privacy-message {
  font-size: 14px;
  line-height: 1.6;
  color: #444;
  font-family: 'Arial', sans-serif;
  padding: 20px;
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: none !important;
}

@media (max-width: 576px) {
  .privacy-title {
      font-size: 1rem;
  }

  .privacy-message {
      font-size: 0.75rem;
  }
}

.outline {
  border: 1px solid #ddd;
  border-radius: 6px;
  color: #fff;
  background-color: #255F79;
  transition: border-color 0.3s ease, color 0.3s ease, background-color 0.3s ease;
}

.outline:hover {
  border-color: #255F79;
  color: #255F79;
  background-color: #fff;
}

.label-container {
  display: flex;
  align-items: center;
}


@media (max-width: 576px) {
  .label-container {
      flex-direction: column;
      align-items: flex-start;
  }
  
  .support-text {
      margin-left: 0;
      margin-top: 0.25rem;
  }

  .input-group {
      width: 100%;
  }

  .input-group .input-group-append .input-group-text {
      width: 100%;
      justify-content: center;
  }
}

.mar-top {
  margin-top: -24px!important;
}

.centerAlign{
  display: flex;
  justify-content: center;
  align-items: center;
}

.sticky-div {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.title-case{
  text-transform: lowercase !important;
}

.title-case::first-letter{
  text-transform: uppercase !important;
}

.no-border-radius {
  border-radius: 0px !important;
}

.align-baseline {
  display: flex;
  align-items: flex-end;
}

.fileExt {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  color: #929292;
  margin-top: 3px;
}


