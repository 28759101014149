.modal-large {
    .modal-content {
        border-radius: 6px !important;
        padding: 16px;
        background: rgb(249, 249, 249);
    }

    button.close {
        position: absolute;
        font-size: 30px;
        top: 16px;
        right: 20px;
    }

    .modal-header {
        border: none;
    }

    .modal-footer {
        border: none;
    }

    .modal-title {
        margin: auto;
    }

    .title {
        text-align: center;

        .mini-title {
            color: #255F79;
            font-size: 12px;
            text-transform: uppercase;
            font-weight: bold;
        }
        .large-title {
            font-size: 24px;
        }
    }

    .modal-body {
        display: flex;
        flex-direction: row;

        // This is needed, to make the x accessible
        padding: 0;
        margin: 1rem;
    }

    .recipient-container {
        width: 200px;
        padding: 16px;

        > div {
            line-height: 30px;
            padding: 0px 8px;
            border-radius: 4px;
            cursor: pointer;

            &.selected {
                background: #5F9FBC;
                color: #ffffff;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
    }

    .message-right-section {
        width: 200px;
    }

    .message-right-section, .message-list-section {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .message-list-section {
        flex-grow: 1;
        padding: 16px;
    }

    .modal-footer-inner {
        padding: 16px;
        display: flex;
        flex-direction: row;

        textarea {
            border: none;
            height: 32px;
            outline: none;
        }
    }

    .recipient-title {
        font-weight: bold;
        font-size: 18px;
        text-transform: uppercase;
    }

    .recipient-container, .message-list-section, .modal-footer-inner {
        background-color: #ffffff;
        margin: 8px;
        border-radius: 8px;
    }

    .message-date-divider {
        text-align: center;
        font-size: 12px;
    }

    .message {
        .message-inner {
            border-radius: 5px;
            padding-left: 12px;
            padding-right: 12px;
            font-size: 14px;

            .sent-date-time {
                font-size: 10px;
            }
        }
    }

    .input-container {
        flex-grow: 1;
    }

    .btn-send-container div {
        cursor: pointer;
    }

    .round-badge{
        color:white;
        font-size: 14px;
        position: absolute;
        border-radius: 50%;
        padding: 2px 10px;
    }

}

// This is for status stepper, to make it accessible and dynamic
$base: 28px;

* {margin: 0; padding: 0; box-sizing: border-box}

.breadcrumb {
	display: flex;
	border-radius: 6px;
    margin: 22px 0px;
	text-align: center;
	top: 50%;
	height: $base * 1.5;
	transform: translateY(-50%);
	z-index: 1;
	background-color: #e8e8e8!important;
	font-size: 14px;
    padding: 0px !important;
    margin-bottom: 0px!important;
    border: 1px solid rgba(0, 0, 0, 0.25);
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */   
}

.breadcrumb div {
    position: relative;
    flex-grow: 1;
    margin: auto;
    height: 100%;
    padding-left: $base;
    padding-right: 0;
    //color: #255F79;
    display: block;
    cursor: pointer;
    white-space: nowrap;
}

.breadcrumb div:first-child {
    padding-left: $base / 2.5;
}

.breadcrumb div:last-child {
    // padding-left: $base / 2.5;
    padding-right: 20px;
}

.breadcrumb div:after {
    content: "";
    position: absolute;
    display: inline-block;
    width: $base * 1.5;
    height: $base * 1.42;
    top: 0;
    right: $base / 1.35 * -1;
    background-color: #e8e8e8;
    border-top-right-radius: 5px;
    transform: scale(0.707) rotate(45deg);
    box-shadow: 1.5px -1.5px rgba(0,0,0,0.25);
    z-index: 1;
}

.breadcrumb div:last-child:after {
    content: none;
}

.breadcrumb__inner {
    display: flex;
    flex-direction: column;
    margin: auto;
    user-select: none; 
    z-index: 2;
}

.breadcrumb__title {
    //font-weight: bold;
    z-index: 2;
}

.breadcrumb div.initial_label_col {
    background: white;
    color: #255F79;
    //box-shadow: 1.5px -1.5px  white;
    border-radius: inherit !important
}

.breadcrumb div.initial_label_col:hover {
    background: #e8e8e8;
    color: #255F;
}

.breadcrumb div.initial_label_col:after {
    background: white;
    color: #255F79;
    box-shadow: 1px solid rgba(0, 0, 0, 0.25);
}

.breadcrumb div.initial_label_col:hover::after {
    background: #e8e8e8;
    color: #255F79;
}

.breadcrumb div.active_label_col {
    background: white;
    color: #255F79;
    border-radius: inherit !important
}

.breadcrumb div.inactive_label_col:hover {
    background: #dedede;
    color: #255F79;
}
.breadcrumb div.inactive_label_col:hover::after {
    background: #dedede;
    color: #255F79;
}

.breadcrumb div.active_label_col:after {
    background: white;
    color: #255F79;
}

.badge-wrapper {
    align-items: center;
    position: absolute;
    left: 50%;
    z-index: 9999;
    margin-top: -2px;
}

// 1000px
///////////////////////
@media all and (max-width: 1000px) {
	.breadcrumb {
		font-size: 12px;
	}
    .badge-wrapper {
        margin-top: 1px;
    }    
}

.stepper-container {
    display: flex;
    //height:43px;
    cursor:pointer;
    display: flex;
    justify-content: center;
}

.arrow-container {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: whitesmoke;
    padding: 6px;
    cursor: pointer;
    border-radius: 6px;
    height: 43px;
}

.arrow-container:hover {
    background-color: #d3d3d3;
}

.dropdown-menu1 {
    position: absolute !important;
    top: 180% !important;
    left: -70px ;
    background-color:  #e8e8e8 !important;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2) !important;
    border-radius: 4px !important;
    z-index: 1000 !important; 
    height: auto !important; 
    transform: translateY(-8px) !important; 
    margin-right: -12px !important; 
    padding: 0px !important; 
    overflow: hidden !important; 
    pointer-events: none !important; 
}

.dropdown-menu1.long-title {
    /* styles for long titles */
    left: -90px ;
}

.dropdown-menu1 ul {
    list-style: none;
    padding: 0;
    margin: 0;
    text-align: left;
    margin: 8px;
}

.dropdown-menu1 li {
    margin-bottom: 8px; 
}

.dropdown-menu1 .list-label {
    font-weight: bold;
    margin-right: 5px;
    color: #255F79;
}

.dropdown-menu1 .badge {
    border-radius: 3px;
    background-color: #255F79;
    color: #fff;
}

.initial_title_col {
    color: #255F79;
    font-weight: bold;
  }
  
.inactive_title_col {
    color: gray !important;
    //opacity: 0.5 !important;
    font-weight: bold;
}

.active_title_col {
    color: #255F79 ;
    font-weight: bold;
}

.custom-badge {
    display: inline-block;
    padding: .25em .4em;
    font-size: 75%;
    font-weight: 700;
    line-height: 1;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: 3px;
    user-select: none;
}

.custom-badge.initial-badge {
    background-color: #255F79;
    color: white;
}

.custom-badge.inactive-badge {
    background-color: gray;
    opacity: 0.5;
    color: white; 
}

.custom-badge.active-badge {
    background-color: #255F79;;
    color: white;
}

//Stepper ends..................................