.sidebar-logo img {
  width: 62%;
  height: 100%;
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh);
  width: 60px;
  padding-top: 0.5rem;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link.active .fa {
  color: #00bafd;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: 0.75rem;
  text-transform: uppercase;
}

.notification-badge[data-count]:after {
  position: absolute;
  right: 5px;
  content: attr(data-count);
  font-size: 0.7rem;
  padding: 0.3em;
  border-radius: 100px;
  color: white;
  background: rgb(222, 73, 73);
  text-align: center;
  min-width: 2em;
}
body {
  padding-top: 60px;
  padding-bottom: 40px;
}

.sidebar-nav {
  padding: 9px 0;
}

.dropdown-menu .sub-menu {
  left: 100%;
  position: absolute;
  top: 0;
  visibility: hidden;
  margin-top: -1px;
}

.dropdown-menu li:hover .sub-menu {
  visibility: visible;
}

.dropdown:hover .dropdown-menu {
  display: block;
}

.nav-tabs .dropdown-menu,
.nav-pills .dropdown-menu,
.navbar .dropdown-menu {
  margin-top: 0;
}

.navbar .sub-menu:before {
  border-bottom: 7px solid transparent;
  border-left: none;
  border-right: 7px solid rgba(0, 0, 0, 0.2);
  border-top: 7px solid transparent;
  left: -7px;
  top: 10px;
}
.navbar .sub-menu:after {
  border-top: 6px solid transparent;
  border-left: none;
  border-right: 6px solid #fff;
  border-bottom: 6px solid transparent;
  left: 10px;
  top: 11px;
  left: -6px;
}
.dropdown-toggle::after {
  display: none !important;
}

.invoice-menu {
  margin-left: 70px !important;
  margin-top: -43px !important;
  /* text-align: center !important; */
  font-weight: 300;
  padding: 5px !important;
}

.pointed {
  position: absolute;
  margin: 0 auto;
  width: 50px;
  height: 60px;
  color: white;
  background-color: rgba(0, 0, 0, 0.8);
}

.pointed:after,
.pointed::after {
  position: absolute;
  top: 5px;
  left: -5px;
  content: "";
  width: 0;
  height: 0;
  border-right: solid 5px rgba(0, 0, 0, 0.8);
  border-bottom: solid 5px transparent;
  border-top: solid 5px transparent;
}

.invoice-link {
  color: #f5f5f5;
}
.invoice-link:hover {
  text-decoration: none;
  color: #8695a4;
}
