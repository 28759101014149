#root {
    > .supplier, > .buyer, > .funder, > .admin {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        display: flex;
        flex-direction: column;
    }
}

.body-container {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    height: 50%;
    

    main {
        flex-grow: 1;
        overflow-y: auto;
        height: 100%;

        >.row {
            margin-left: 0;
            margin-right: 0;
            width: 100%;
        }

        .header-titlebar {
            padding: 0.75rem 1.5rem;

            h4{
                margin: 0;
            }
        }

        .content-container {
            padding: 0.75rem 1.5rem 0;
        }
    }
}

.login-wrapper::before, .login-wrapper::after {
    content: " ";
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.login-wrapper::after {
    z-index: -2;
}
.login-wrapper  {
    height: 100vh;
}

.login-wrapper::after {
    z-index: -2;
    background: linear-gradient(180deg, #99d5ef, #aed8f0, #c4d9ee);
}


/// documents page stuff - move it elsewhere
.nav-tabs {
    border-bottom: none !important;

    a.nav-link {
        color: #ADB4BA !important;
        font-weight: bold;
        padding: 0;

        &.active {
            color: #255F79 !important;
            border-bottom: none !important;
        }
    }

    .nav-item:not(:first-child) {
        margin-left: 16px;
    }
}

// @media screen and (min-width: 480px) {
//     .signup-form-width{
//         width:57%;
//     }
// }

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .copyright-text{
        text-align: center;
    }

    .title-form-page{
        color:white;
        font-size: 1.75rem;
        font-weight: 600;
        text-transform: capitalize;
    }
    .login-wrapper::before {
        z-index: -1;
        background-image: url('../landingpage_bg.jpg');
        background-position: right;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .signup-logo {
        margin: 10px;
      }
    .signup-col-width{
        width:100%;
    }
    
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {

}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {

    .title-form-page {
        color: white;
        font-size: 36px;
        font-weight: 600;
        text-transform: capitalize;
    }
    .login-wrapper::before {
        z-index: -1;
        background-image: url('../landingpage_bg.jpg');
        background-position: right;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
    .signup-logo {
        margin: 10px;
      }
}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {
    .signup-form-width{
        width:57%;
    }
    .login-wrapper::before {
        z-index: -1;
        background-image: url('../landingpage_bg.jpg');
        background-position: right;
        background-size: contain;
        background-repeat: no-repeat;
    }
    .signup-logo {
        margin: 10px;
        /* width: 267px; */
        height: 32px;
    }
    .signup-col-width{
        width:50%;
    }
}

@media only screen and (min-width: 1280px) {
    .signup-col-width{
        margin-top: 3rem;
    }
}


  @media only screen and (min-device-width: 481px) and (max-device-width: 1024px) {
    /* For portrait layouts only */
    .signup-form-width{
        width:57%;
    }
    .signup-col-width{
        width:100%;
    }
    .signup-logo {
        margin: 10px;
      }
      .login-wrapper::before {
        z-index: -1;
        background-image: url('../landingpage_bg.jpg');
        background-position: right;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }
  }

