.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99999;
}
.text{
  padding: 1%; 
  overflow: auto;
} 
.box {
  position: relative;
  width: 60%;
  margin: auto;
  height :80%;
  max-height:80%;
  margin-top: 5%;
  border: 1px solid #999;
  background: #F9F9F9;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.box > .popup {
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.fundertcfbox {
  position: relative;
  width: 60%;
  margin: auto;
  height :80%;
  max-height:80%;
  margin-top: 5%;
  border-radius: 10px;
  /* padding: 5px; */
  border: 1px solid #999;
  background:white;
}
.popup-close-icon:before {
  content: '\00D7';
}
.popup-close-icon {
  cursor: pointer;
  width: 25px;
  height: 25px;
  line-height: 24px;
  float: right;
  justify-content: right;
  font-size: 40px;
  background-color:transparent;
  border: none;
  outline:none;
}
.popup-download-icon {
  margin: 5px;
  width: 25px;
  height: 25px;
  line-height: 24px;
  float: right;
  justify-content: right;
  font-size: 25px;
  background-color:transparent;
  color: black;
  border: none;
  outline:none;
}
.popup-content{
  height:70vh;
  max-height:70vh;
  color:black;
  background-color:white;
  overflow: auto;
}
.popup-text{
  overflow: auto;
}

.popup-box.big {
  padding: 16px;
}

.popup-box.big .box {
  margin-top: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
}
.popup-box.medium {
  padding: 16px;
}

.popup-box.medium .box {
  margin-top: 0;
  width: 80%;
  height: 100%;
  max-height: 100%;
}

.popup-box.mini .box {
  margin-top: 20vh;
  width: 512px;
  height: 300px;
}

.popup-box.supplier-singed-modal .box {
  margin-top: 15vh;
  width: 90%;
  height: 80%;
  max-height: 75%;;
}


.popup-body-container {
  position: relative;
  height: calc(100% - 60px);
  overflow-y: scroll;
}

.popup-body-container > div {
  position: absolute;
  width: 100%;
}

.popup-box.funder-description .box {
  margin-top: 15vh;
  width: 55%;
  height: 65%;
  max-height: 70%;;
}