.funder {
    .content-container.documents-list{
        // display: none;
        height:10px;
    }
    .marketplace-list .active{
        display: flex!important;
        flex-direction: column;
        height: 65vh;
    }

    .round-badge{
        background-color:#5ea03b;
    }

    .message.sent .message-inner {
        background: #5ea03b;
        color: #ffffff;
    
        a {
            color: #ffffff !important;
        }
    }
    
    .message.received .message-inner {
        background: #063A52;
        color: #ffffff;
    
        a {
            color: #ffffff !important;
        }
    }
}

.header-navbar, .side-navbar {
    background-color: #063A52;
}

.header-titlebar {
    background-color: #F9F9F9;

    h4{
        color: #063A52;
    }
}

.login-wrapper {
    background: linear-gradient(97.42deg, rgba(13, 27, 35, 0.75) 70.32%, rgba(13, 24, 33, 0) 90.36%);
}

.awaiting-text-color{
    color: #4F4F4F
}
.archived-text-color{
    color: #930000
}
.progress-text-color{
    color: #4F984F
}

h4,h5{
    color: #063A52;
}

.link-text-color
,option{
    color:#5F9FBC;
}

.bg-color{
    background-color: #F9F9F9
}