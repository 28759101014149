.card {
  border: 0px solid rgba(0, 0, 0, 0.125);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0px;
}
.notification-card {
  cursor: pointer;
}
.cusName > *{
  margin-left: 1.5em;
}
.message-list {
  background-color:white;
  width: 100%;
  overflow-y: scroll;
  flex-grow: 1;
  position: relative;
}
.message-listSuperView {
  position: absolute;
  width: 100%;
}
.textinput{
  width: 100%;
  height: 100px;
  resize: none;
  padding: 4px 8px;
}
.sendButton{
  background-color: #062f53;
  color: white;
  border: 1px solid   #062f53;
  width: 80px;
}
.attachButton{
  background-color:white;
  color: white;
  border: 1px solid white; 
}

.messageRow{
  display: flex; 
  justify-content: flex-end;
}
.rightText{
 display: flex; 
 justify-content: flex-end;
 padding: 10px;
 margin-left: 60%;
 background-color: rgb(224, 227, 230);
}
.leftText{
  padding: 10px;
  margin-left: 60%;
  background-color: rgb(224, 227, 230);
 }

.textshadow {
  box-shadow: 0px 0px 2px 1px rgba(0,0,0,0.5);
}

.message {
  padding: 8px;
}

.message.sent {
  text-align: right;
}

.message.received {
  text-align: left;
}

.message-inner {
  padding: 4px;
  display: inline-block;
}
.message-view {
  max-width: 75% !important;
  height: 90%;
  margin: auto;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
.modal-content {
  height: 100% !important;
}
.modal-body {
  display: flex;
  flex-direction: column;
}

