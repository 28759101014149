.supplier {
  .hide{
    display: none;
  }
  .p-a{
    position: absolute;
  }
  .apexcharts-menu-icon{
    display: none;
  }
  .formErrors{
    color: red;
    font-size: small;
  }
  .card-height_profile {
    height: 330px;
  }
  #acceptFundRequestModal{
    font-size: small !important;
    padding-left:58px;
    .offerNote-width{
      width: 250px !important;
    }
  }
  .dropZone{
    div{
      height: 100px !important;
      padding: 20px;
      // background: #FFFFFF !important;
      // border: 1px dashed #999999 !important;
      border:none !important;
      background-image: url("./assets/images/Rectangle.png");
      box-sizing: border-box;
      border-radius: 5px !important;
      background-repeat: no-repeat;
    }
  }
  div.dropdown-menu{
    padding: 10px;
    button.dropdown-item{
        font-size: large;

    }
  }
  .card-body{
    padding: 1.20rem !important;
  }
  .legend-pos{
    margin-top: -25px;
    margin-bottom: 8px;
  }
  .modal-max-width{
    max-width:1230px !important;
  }
  .card-info{
    background-image: linear-gradient(-20deg, #002f53 0%, rgba(14, 48, 74, 0.52) 100%);
    color: white;
  }
  .sort-icon-asc{
    font-size: medium;
    margin-top: 6px;
    margin-left: 6px;
    position: absolute;
  }
  .sort-disable{
    color: darkgray !important;
  }
  .sort-icon-desc{
    font-size: medium;
    margin-top: 6px;
    margin-left: 6px;
    position: absolute;
  }
  
  .grid-header{
    padding: 10px;
    color: white;
    span{
      text-transform:uppercase;
      font-size: small;
    }
    background-image: linear-gradient(to bottom, #002f53 0%, #012440 100%);
  }
  .text-white{
    color: white !important;
  }
  .grid-rows {
    font-size: small;
    & > .row{
      border-top: 1px solid #dee2e6 !important;  
    }
    & > .row:first-child {
      border-top: none !important;
    }
    .actionBtn{
      & > button{
        height: 30px;
        width: 90px;
        padding: 0px;
        padding-right: 15px;
        svg{
          position: absolute;
          margin-top: 2px;
          margin-left: 5px;
        }
      }
    }
  }
  .loadProgress{
    text-align: center;
    margin-top: 6em;
  }
  .namelbl{
    font-weight: bold;
    white-space: nowrap;
  }
  
  .card-height{
    height: 400px;
  }
  .row-space-7em{
    margin-top: 7em;
  }

  /* Buttons */
  .btn-primary {
    background-color:  #255F79 !important;
    border-color:  #255F79 !important;
    letter-spacing: 0.2px;
  }

  .btn-primary:hover {
    background-color: #1c475b !important; 
    border-color: #1c475b !important; 
  }

  .btn-outline-primary {
    color:  #255F79 !important;
    border-color:  #255F79 !important;
  }

  .btn-outline-primary:hover {
    background-color:  #255F79 !important;
    color: white !important;
    border-color:  #255F79 !important;
  }

  .btn-link {
    color: #2A2C44 !important;
  }

  .grid-height{
    height: 63vh;
    margin-right: 0px !important;
  }
  .grid-row-scroll{
    max-height: 55.5vh;
    overflow: auto;
    overflow-x: hidden;
    margin-right: -15px;
  }
  .anchorstyle{
    cursor: pointer;
    text-decoration: none;
    font-weight: bold;
    color:#5F9FBC;
  }
  .document-border{
    border: 1px solid #6c757d!important;
    padding: 10px;
    border-radius: 0px;
    margin-left: 1.5em;
  }
  .view-modal-right-scroll{
    max-height: 69vh;
    overflow: auto;
    overflow-x: hidden;
  }
  .select-funders-popup .box {
    overflow: auto;
    height: auto;
    max-height: 512px;
    width: 60%;
    overflow-y: scroll;
}
.buyer-submit-popup .box {
  width: 570px;
  height: 525px;
  background: #FFFFFF;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}
.alert-zindex{
  z-index:99999;
}
.card-shadow{
  box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.3);
  // flex: 1 0 50%;
  margin:10px;
  // width:48%;
}
.flex-container{
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
}

}

.dashboard-card-body {
  height: 300px;
  overflow: auto;
  position: relative;
}

.aging-report-modal .box {
  width: 604px;
  height: 486px;
  background: #FFFFFF;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.btn-danger{
  background: #930000 !important;
}

.btn-secondary{
  background: #5F9FBC !important;
  border-color:  #5F9FBC !important;
  letter-spacing: 0.2px;
}

.btn-secondary:hover {
  background: #4f8aa2 !important; 
  border-color: #4f8aa2 !important; 
}

.view-modal-left-scroll{
  max-height: 48vh;
  overflow: auto;
  overflow-x: hidden;
}

.btn-primary:disabled{
  background-color: #999999!important;
  border-color:  #999999 !important;
}

.btn-primary:disabled:hover {
  background-color: #999999 !important; 
  border-color: #999999 !important; 
}

.model-btn-style{
  text-transform: uppercase;
  font-weight: 700;
  color:white;
}

.btn-submit-agreement {
  margin-top: 70px !important;
}

.bank-statement-modal .box {
  width: 750px;
  height: 486px;
  background: #FFFFFF;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.1);
  border-radius: 20px;
}

.rt-tbody{
  max-height: 30vh;
}
.rt-table{
  max-height: 30vh;
}

.po-upload-popup .box {
  height: 90% !important;
  max-height: 90% !important;
  width: 75%;
  margin-top: 2.5%;
  overflow: hidden;
}

.poupload>div{
  height: 25vh !important;
  border-radius: 0px !important;
  width:32vw!important;
  overflow-y: auto;
  text-align: center;
}