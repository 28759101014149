.header-navbar, .side-navbar {
    background-color: #063A52;
}

.header-titlebar {
    background-color: #F9F9F9;

    h3{
        color: #063A52;
    }
}

.login-wrapper {
    background: linear-gradient(97.42deg, rgba(13, 27, 35, 0.75) 70.32%, rgba(13, 24, 33, 0) 90.36%);
}

.awaiting-text-color{
    color: #4F4F4F
}
.archived-text-color{
    color: #930000
}
.progress-text-color{
    color: #4F984F
}

h4,h5, .theme-color{
    color: #063A52;
}

.link-text-color
,option{
    color:#5F9FBC;
}

.normal-text-color{
    color: #575757;
}

.MuiBadge-colorPrimary{
    background-color:#063A52!important;
}

.amount-color{
    color:#255F79;
}