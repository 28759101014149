.popup-container{
    padding: 16px;
    display: flex;
    flex-direction: column;
    font-size: large;
}
.popup-container label{
    color: #255F79;
}
.popup-header-container{
    display: flex;
    flex-direction: row;
    color: #255F79;
    justify-content: space-between;
}

/* .popup-header-container h3{
    justify-self: flex-start;
}*/

.popup-header-container .cross-icon:hover{
    cursor: pointer;
} 

.user-data-row-1{
    display: flex;
    flex-direction: row;
    margin: 10px 0px;
} 

.padding-right{
    padding-right: 35px;
}

.user-list {
    height: calc(100% - 90px);
    display: flex;
}

.data-grid-th {
    background: white !important;
}