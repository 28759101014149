.user-name-display {
  float: right;
  right: 0;
  top: 0;
  position: absolute;
  margin: 20px 30px 0 0;
  color: #222529;
  font-weight: bold;
  font-size: 18px;
  border-radius: 50px;
  height: 48px;
  background: #ffffff;
  border-radius: 50px;
  text-align: center;
  padding: 10px;
  border: 0.5px solid #222529;
}
.icon-user {
  margin-right: 10px;
}

.icon-badge {
  float: right;
    right: 204PX;
    top: 16PX;
    POSITION: absolute;
    margin: 20px 30px 0 0;
    width: 40px;
    height: 40px;
}
.tab-root-view {
  margin-left: 10%;
  /* background-color: #062f53; */
}

.verfication-badge {
  font-size: 12px !important;
  vertical-align: top !important;
  margin-left: 8px;
}
.form-section {
  padding: 8px;
  margin: 16px 0px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.5);
}
.form-label {
  font-weight: bold;
  display: block;
}
.form-value {
  display: block;
  padding-left: 18px;
  word-wrap: break-word;
}

.navbar .navbar-nav li a {
  color: #fff;
}

.sidebar .nav-link {
  font-family: Cairo;
  font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 34px;
display: flex;
align-items: center;
  /* font-weight: 300;
  font-size: 1rem; */
  color: white;
  cursor: pointer;
}
.sidebar .nav-link li a:hover  {
  color: #82CEF1;
  -webkit-transition: .2s;
  transition: .2s;
}

.sidebar .nav-link.active {
  color: #82CEF1;

}
body{
  background-color: #FFFFFF!important;
  display: flex;
  flex-direction: column;
}

.font-icon-width{
  width: 25px;
  text-align: center;
}
/* 
.header-area{
  background: #F9F9F9;
} */

.sticky-top{
  position: sticky;
    top: 0;
    z-index: 1020;
}

::-webkit-scrollbar {
  width: 8px;
  height:8px;
}

::-webkit-scrollbar-track {
  background: #E7E7E7;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #B6B6B6;
  border-radius: 5px;
}