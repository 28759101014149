.addButton {
    margin-left: 87%;
}

.dropbox-containerMedium {
    margin-top: 20px;
}

.dropbox-containerMedium>div {
    width: 100% !important;
    padding: 8px;
    height: 200px !important;
    overflow-y: auto;
}

.dropbox-containerMedium input {
    width: 100%;
}

.ablupload>div{
    height: 30vh !important;
    border-radius: 0px !important;
    width:32vw!important;
    overflow-y: auto;
    text-align: center;
  }